.hero{
    display: inline-flex;
    padding: 0px 100px 100px 100px;
    align-items: flex-start;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include xl{
        padding: 0 40px 40px 40px;
    }
    @include sm{
        padding:  0 20px 20px 20px;
    }
    @include s{
        padding: 0 10px 10px 10px;
    }
    &__left{
        &-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @include s{
                margin-right: 8px;
            }
            &-text{
                @include h1;
                color: $bright-grey;
                text-align: center;
                @include xl{
                    font-size: 50px;
                }
                @include lg{
                    font-size: 35px;
                }
                @include sm{
                    font-size: 24px;
                }
                @include s{
                    font-size: 20px;
                }
                &-in{
                    background: $Green-yellow-gradient;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            &-button{
                margin-top: 36px;
                @include button;
                cursor: pointer;
                @include xl{
                    font-size: 18px;
                    padding: 8px 24px;
                }
                @include lg{
                    padding: 4px 18px;
                }
                @include sm{
                    margin-top: 8px;
                }
                @include s{
                    @include button-s;
                    font-size: 12px;
                    padding: 3px 10px;
                }
            }
        }
    }
    &__center{
        display: flex;
        align-items: self-end;
        transform: rotate(180deg);
        writing-mode: vertical-rl;;
        &-text{
            color: $bright-grey;
            text-align: center;
            font-family: Sen;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @include xl{
                font-size: 24px;
            }
            @include s{
                font-size: 14px;
            }
            &-header{
                color: $Yellow;
                font-family: Dela Gothic One;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include xl{
                    font-size: 24px;
                }
                @include s{
                    font-size: 14px;
                }
            }
        }
    }
    &__right{
        width: 682px;
        height: 424px;
        background-image: url(/assets/hero/Illustration.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        @include xl{
            width: 480px;
        }
        @include lg{
            height: 284px;
            width: 340px;
        }
        @include sm{
            height: 212px;
            width: 284px;
        }
        @include s{
        width: 215px;
        height: 147px;
        position: relative;
        right: -5%;
        }
    }
}