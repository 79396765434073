.featches{
    display: inline-flex;
    padding: 0px 100px 100px 100px;
    flex-direction: column;
    align-items: flex-start;
    @include xl{
        padding: 0 40px 40px 40px;
    }
    @include sm{
        padding:  0 20px 20px 20px;
    }
    &__title{
        @include h2;
        @include h2_color;
        @include lg{
            @include h2-lg;
        }
        @include s{
            @include h2-s;
        }
    }
    &__container{
        display: flex;
        align-items: center;
        align-content: center;
        gap: 34px 100px;
        flex-wrap: wrap;
        @include article;
        text-align: justify;
        @include xl{
            justify-content: center;
        }
        @include sm{
            gap: 34px;
            line-height: normal;
            padding-bottom: 20px;
        }
        @include s{
            @include article-s;
            gap: 10px;
        }
        &-2{
            flex-direction: row-reverse;
            margin-bottom: 60px;
            @include lg{
                margin-bottom: 20px;
            }
        }
        &-img{
            width: 265.2px;
            height: 260px;
            flex-shrink: 0;
            @include sm{
                width: 200px;
                height: 200px;
            }
            &-clients{
                background-image: url(/assets/featches/clients.png);
                background-repeat: no-repeat;
                @include sm{
                    background-size: contain;
                }
            }
            &-gambling{
                background-image: url(/assets/featches/gambling.png);
                background-repeat: no-repeat;
                @include sm{
                    background-size: contain;
                }
            }
        }
        &-textbox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 32px;
            flex: 1 0 0;
            @include s{
                gap: 10px;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; 
            }
        }
        &-icon{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 32px;
            min-width: 344px;
            @include lg{
                gap: 16px;
                min-width: 260px;
            }
            @include sm{
                min-width: 160px;
            }
            &-img{
                width: 84px;
                height: 84px;
                @include sm{
                    width: 40px;
                    height: 40px;
                }
                &-support{
                    background-image: url(/assets/featches/support.png);
                    background-repeat: no-repeat;
                    @include sm{
                        background-size: contain;
                    }
                }
                &-costs{
                    background-image: url(/assets/featches/costs.png);
                    background-repeat: no-repeat;
                    @include sm{
                        background-size: contain;
                    }
                    
                }
                &-portability{
                    background-image: url(/assets/featches/portability.png);
                    background-repeat: no-repeat;
                    @include sm{
                        background-size: contain;
                        width: 60px;
                    }
                    
                    width: 163px;
                }
                &-security{
                    background-image: url(/assets/featches/security.png);
                    background-repeat: no-repeat;
                    @include sm{
                        background-size: contain;
                    }
                    
                }
                &-transparency{
                    background-image: url(/assets/featches/transparency.png);
                    background-repeat: no-repeat;
                    @include sm{
                        background-size: contain;
                    }
                    
                }
            }
            &-title{
                color: $bright-grey;
                font-family: Sen;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%; /* 39.2px */
                @include sm{
                    font-size: 18px;
                }
                @include s{
                    font-size: 12px;
                }
            }
        }
    }
}