.footer{
    display: flex;
    justify-content: center;
    padding: 0 100px;
    background: #353535;
    @include xl{
        padding: 0 40px;
    }
    @include s{
        padding: 0 20px;
    }
    &__container{
        position: relative;
        display: inline-flex;
        width: 1440px;
        padding: 60px 0px 60px 0px;
        flex-direction: column;
        justify-content: space-between;
        gap: 60px;
        @include s{
            padding: 40px 0 20px 0;
            gap: 20px;
        }
        &-title{
            position: absolute;
            display: flex;
            color: #FDDD33;
            font-family: Dela Gothic One;
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            justify-content: center;
            top: -5%;
            left: 50%;
            transform: translate(-50%);
            @include sm{
                font-size: 35px;
                top: -3%;
            }
            @include s{
                font-size: 20px;
                top: -3.3%;
            }
        }
        &-info{
            display: flex;
            padding: 0px 100px;
            justify-content: space-between;
            align-items: flex-start;
            @include xl{
                padding: 0 40px;
            }
            @include lg{
                flex-wrap: wrap;
                margin: 0 auto;
                justify-content: space-around;
                padding: 0 20px;
            }
            @include md{
                justify-content: space-between;
            }
            @include s{
                padding: 0;
            }
            &-links{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 40px;
                @include lg{
                    width: 30%;
                    justify-content: space-between;
                    gap: 16px;
                    margin-bottom: 30px;
                }
            }
            &-link{
                color: $bright-grey;
                text-align: center;
                font-family: Sen;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%; /* 35.2px */
                @include xl{
                    font-size: 18px;
                }
                @include lg{
                    text-align: left;
                }
                @include sm{
                    min-width: 170px;
                }
                @include s{
                    font-size: 12px;
                    min-width: 120px;
                }
            }
            &-link:hover{
                color: yellow;
            }
            &-about{
                width: 256px;
                flex-shrink: 0;
                @include lg{
                    width: 100%;
                }

                &-company{
                    color: #EAEAEA;
                    text-align: justify;
                    /* Body txt (reg) 18 */
                    font-family: Sen;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; /* 28.8px */
                    @include s{
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }
        }
        &-social{
            display: flex;
            padding: 0 100px;
            justify-content: space-between;
            align-items: flex-start;
            @include xl{
                padding: 0 40px;
            }
            @include lg{
                flex-wrap: wrap;
                justify-content: center;
                gap: 10px;
            }
            @include sm{
                justify-content: space-between;
            }
            @include s{
                padding: 0;
                justify-content: center ;
            }
            &-link{
                color: #EAEAEA;
                text-align: center;
                /* Heading (reg) 22 */
                font-family: Sen;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px; /* 127.273% */
                display: flex;
                align-items: center;
                @include sm{
                    min-width: 170px;
                }
                @include s{
                    font-size: 12px;
                    line-height: 20px; 
                    width: auto;
                    min-width: auto;
                }
                &-facebook::before{
                    content: url(../assets/footer/facebook.png);
                    width: 38px;
                    height: 38px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin-right: 24px;
                    @include s{
                        margin-right: 8px;
                        width: 24px;
                        height: 24px;
                        content: url(../assets/footer/facebook-s.png);
                    }
                }
                &-instagram::before{
                    content: url(../assets/footer/instagram.png);
                    width: 38px;
                    height: 38px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin-right: 24px;
                    @include s{
                        margin-right: 8px;
                        width: 24px;
                        height: 24px;
                        content: url(../assets/footer/instagram-s.png);
                    }
                }
                &-linkedin::before{
                    content: url(../assets/footer/linkedin.png);
                    width: 38px;
                    height: 38px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin-right: 24px;
                    @include s{
                        margin-right: 8px;
                        width: 24px;
                        height: 24px;
                        content: url(../assets/footer/linkedin-s.png);
                    }
                }
                &-skype::before{
                    content: url(../assets/footer/skype.png);
                    width: 38px;
                    height: 38px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin-right: 24px;
                    @include s{
                        margin-right: 8px;
                        width: 24px;
                        height: 24px;
                        content: url(../assets/footer/skype-s.png);
                    }
                }
                &-twitter::before{
                    content: url(../assets/footer/twitter.png);
                    width: 38px;
                    height: 38px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin-right: 24px;
                    @include s{
                        margin-right: 8px;
                        width: 24px;
                        height: 24px;
                        content: url(../assets/footer/twitter-s.png);
                    }
                }
            }
            &-link:hover{
                color: yellow;
            }
        }
        &-cards{
            display: flex;
            align-items: flex-start;
            gap: 44px;
            justify-content: center;
            @include lg{
                flex-wrap: wrap;
                gap: 20px;
            }
        }
        &-card{
            width: 76.104px;
            height: 48px;
            @include s{
                width: 50.736px;
                height: 32px;
            }
            &-visa{
                background-image: url(../assets/footer/visa.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
            &-mastercard{
                background-image: url(../assets/footer/mastercard.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
            &-googlepay{
                background-image: url(../assets/footer/googlepay.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
            &-applepay{
                background-image: url(../assets/footer/applepay.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
            &-mbway{
                background-image: url(../assets/footer/mbway.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
            &-paypal{
                background-image: url(../assets/footer/paypal.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
            &-pcidss{
                background-image: url(../assets/footer/pci_dss.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
        &-rights{
            color: #EAEAEA;
            text-align: center;
            font-family: Sen;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; 
            padding-bottom: 60px;
            text-align: right;
            padding: 0 100px;
            @include lg{
                text-align: right;
                padding: 0;
            }
            @include s{
                font-size: 12px;
                line-height: 20px; 
            }
        }
    }
}