.select {
	position: relative;
    border-radius: 20px;
    margin-bottom: 20px;
}

.select._error{
	border: 3px solid #FD3333;
}

option[default] {
    display: none;
  }

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
/* Track */
::-webkit-scrollbar-track {
  background: #FFFADF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FDDD33;
}

.select__item {
	position: relative;
}


.select__title {
	display: flex;
	width: 600px;
	height: 76px;
	padding: 20px 248px 20px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	border-radius: 20px;
	background: #FFFADF;
	cursor: pointer;
	@include xl{
		width: 100%;
		padding: 0;
	}
	@include s{
		height: 40px;
		border-radius: 12px;
		margin-top: 10px;
	}
}



.select__value {
	display: flex;
	font-size: 16px;
	height: 50px;
	padding: 0px 20px;
	align-items: center;
	justify-content: space-between;
	flex-direction: row-reverse;
}
.select__value span {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
    color: grey;
}
.select__value::before {
	content: "";
	display: inline-block;
	transition: all 0.3s ease 0s;
	width: 11px;
	height: 7px;
	background: url("../assets/form/select.svg") center / 100% no-repeat;
	position: absolute;
	right: 4%;
}

.select__options {
	color: #75746A;
	display: none;
	position: absolute;
	top: 49px;
	border-radius: 20px;
	left: 0;
	background: #FFFADF;
	border-top: 0;
	border-top: 0;
	font-size: 16px;
	padding: 0px 0px 5px 0px;
    max-height: 310px;
    overflow: auto;
	@include xl{
		width: 100%;
	}
	@include s{
		border-radius: 12px;
	}
}

.select__option:hover {
    color: #FDDD33;
    font-size: 18px;
}

.select__option {
	cursor: pointer;
	padding: 10px 20px;
	margin: 0px 0px 0px 0px;
}

.select._active {
	z-index: 5;
}
.select._active .select__value::before {
	transform: rotate(-180deg);
}
.select._active.select__options {
	display: block;
}
