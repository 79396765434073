.services{
    display: flex;
    padding: 0px 100px 80px 100px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include xl{
        padding:  0 40px 40px 40px;
        position: relative;
    }
    @include sm{
        padding:  0 20px 20px 20px;
    }
    &__container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &-title{
            @include h2;
            @include h2_color;
            @include lg{
                @include h2-lg;
            }
            @include sm{
                padding: 30px 0 20px 0;
            }
            @include s{
                @include h2-s;
                font-size: 26px;
            }
        }
        &-wrapper{
            display: flex;
            width: 807px;
            align-items: flex-start;
            gap: 56px;
            @include lg{
                width: 100%;
            }
            @include sm{
                width: 100%;
                gap: 16px;
            }
            &-title{
                transform: rotate(180deg);
                writing-mode: vertical-rl;
                color: $bright-grey;
                text-align: center;
                font-family: Sen;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                @include s{
                    font-size: 20px;
                }
                &-yellow{
                    color: #FDDD33;
                    font-family: Dela Gothic One;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    @include s{
                        font-size: 20px;
                    }
                }
            }
            &-box{
                @include article;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                flex: 1 0 0;
                @include sm{
                    font-size: 16px;
                }
                @include s{
                    @include article-s;
                }
                &-text{
                    align-self: stretch;
                }
                &-bullet{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    @include s{
                        gap: 10px;
                    }
                    &-aim::before{
                      content: url("/assets/services/mark.png");
                      width: 32.684px;
                      height: 24px;
                      flex-shrink: 0;
                      align-items: center;
                      margin-right: 17px;
                      @include s{
                        content: url("/assets/services/mark-s.png");
                        width: 17px;
                        height: 12.483px;
                        margin-right: 8px;
                      }  
                    }
                    &-aim{
                        display: flex;
                        text-align: center;
                        align-items: center;
                    }
                }
            }
        }
    }
    &__wrapper{
        width: 354px;
        height: 304px;
        flex-shrink: 0;
        background-image: url(/assets/services/services.png);
        background-repeat: no-repeat;
        @include xl{
            position: absolute;
            background-size: contain;
            width: 280px;
            right: 5%;
            bottom: -5%
        }
        @include lg{
            width: 200px;
        }
        @include sm{
            width: 150px;
            bottom: -17%;
        }
        @include s{
            display: none;
        }
    }
}

.our-services{
    display: flex;
    padding: 0px 100px 0px 100px;
    flex-direction: column;
    align-items: flex-start;
    @include xl{
        padding: 0 40px 40px 40px;
    }
    @include lg{
        padding: 0 40px 0 40px;
    }
    @include s{
        padding: 0 20px 0 20px;
    }
    &-title{
        @include h2;
        @include h2-color;
        @include lg{
            @include h2-lg;
        }
        @include sm{
            padding: 30px 0 0 0;
        }
        @include s{
            @include h2-s;
        }
    }
    &__container{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        align-self: stretch;
        flex-wrap: wrap;
        &-card{
            display: flex;
            width: 346px;
            flex-direction: column;
            align-items: center;
            margin-bottom: 100px;
            @include xl{
                width: 440px;
            }
            @include lg{
                width: 325px;
            }
            @include sm{
                width: 230px;
                margin-bottom: 20px;
            }
            @include s{
                width: 160px;
                margin-bottom: 10px;
            }
            &-img{
                width: 232px;
                height: 232px;
                flex-shrink: 0;
                background-image: url(/assets/our-services/Account.png);
                background-repeat: no-repeat;
                background-position: center;
                @include sm{
                    height: 100px;
                    width: 100px;
                    background-size: contain;
                }
                &2{
                    width: 232px;
                    height: 232px;
                    flex-shrink: 0;
                    background-image: url(/assets/our-services/Law.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    @include sm{
                        height: 100px;
                        width: 100px;
                        background-size: contain;
                    }
                }
                &3{
                    width: 232px;
                    height: 232px;
                    flex-shrink: 0;
                    background-image: url(/assets/our-services/glass.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    @include sm{
                    height: 100px;
                    width: 100px;
                    background-size: contain;
                }
                }
                &4{
                    width: 232px;
                    height: 232px;
                    flex-shrink: 0;
                    background-image: url(/assets/our-services/cash.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    @include sm{
                    height: 100px;
                    width: 100px;
                    background-size: contain;
                }
                }
                &5{
                    width: 232px;
                    height: 232px;
                    flex-shrink: 0;
                    background-image: url(/assets/our-services/Cards.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    @include sm{
                    height: 100px;
                    width: 100px;
                    background-size: contain;
                }
                }
                &6{
                    width: 232px;
                    height: 232px;
                    flex-shrink: 0;
                    background-image: url(/assets/our-services/terminals.png);
                    background-repeat: no-repeat;
                    background-position: center;
                                    @include sm{
                    height: 100px;
                    width: 100px;
                    background-size: contain;
                }
                }
            }
            &-title{
                color: $bright-grey;
                padding-bottom: 24px;
                text-align: center;
                /* Heading (bold) 22 */
                font-family: Sen;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%; /* 35.2px */
                @include sm{
                    font-size: 18px;
                }
                @include s{
                    font-size: 12px;
                    padding-bottom: 10px;
                    line-height: 20px;
                }
            } 
            &-article{
                text-align: justify;
                @include article;
                @include sm{
                    font-size: 14px;
                }
                @include s{
                    @include article-s;
                }
            } 
        }
    } 
}