.navigation{
    display: flex;
    height: 140px;
    padding: 0px 100px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 32px;
    @include xl{
        padding: 0 40px;
        margin-bottom: 24px;
        height: 100px;
    }
    @include lg{
        height: 70px;
    }
    @include s{
        padding: 12px 10px;
        height: 48px;
        margin-bottom: 10px;  
    }
    &__burger-menu{
        display: none;
        @include lg{
            display: block;
        }
    }
    &__title{
        color: #FDDD33;
        font-family: Dela Gothic One;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @include s{
            font-size: 18px;
            line-height: 18px;
        }
    }
    &__links{
        display: flex;
        justify-content: center;
        align-items: center;
        color:#EAEAEA;
        text-align: center;
        font-family: Sen;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        @include lg{
            display: none;
        }
    }
    &__link{
        color:#EAEAEA;
        text-align: center;
        font-family: Sen;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
    }
    &__buttons{
        display: flex;
        width: 393px;
        justify-content: space-between;
        align-items: flex-start;
        flex-shrink: 0;
        @include xl{
            width: 300px;
        }
        @include lg{
            display: none;
        }
        &-contact{
            display: flex;
            padding: 12px 32px;
            cursor: pointer;
            align-items: center;
            gap: 10px;
            border-radius: 15px;
            border: 2px solid #FFA939;
            background: linear-gradient(284deg, #FFA939 -36.16%, #FDDD33 97.4%);
            color: #474747;
            text-align: center;
            font-family: Sen;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px; /* 116.667% */
            @include xl{
                font-size: 18px;
                padding: 8px 24px;
            }
        }
        &-sign_in{
            display: flex;
            cursor: pointer;
            padding: 12px 32px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 15px;
            border: 3px solid #EAEAEA;
            color: #EAEAEA;
            text-align: center;
            font-family: Sen;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px; /* 116.667% */
            @include xl{
                font-size: 18px;
                padding: 8px 24px;
            }
        }
    }
}

@include s{
    .ttt{
        width: 24px;
        height: 24px;
    }
}

.top-nav {
    padding: 20px 40px 0 40px;
    margin-bottom: 24px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: right;
    margin: 0;
    padding: 0;
  }
  
  .menu > li {
    margin: 0 1rem;
    overflow: hidden;
  }
  
  .menu-button-container {
    display: none;
    height: 100%;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  #menu-toggle {
    display: none;
  }
  
  .menu-button,
  .menu-button::before,
  .menu-button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
  }
  
  .menu-button::before {
    content: '';
    margin-top: -8px;
  }
  
  .menu-button::after {
    content: '';
    margin-top: 8px;
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
  }
  
  @include lg {
    .menu-button-container {
      display: flex;
      align-items: flex-end;
    }
    .menu {
      position: absolute;
      top: 50px;
      margin-top: 20px;
      left: 0;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      z-index: 5;
    }
    #menu-toggle ~ .menu li {
      height: 0;
      margin: 0;
      padding: 0;
      border: 0;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    #menu-toggle:checked ~ .menu li {
      border: 1px solid rgb(122, 122, 58);
      height: 2.5em;
      padding: 0.5em;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .menu li:hover{
      color: yellow;
      cursor: pointer;
    }
  
    .menu > li {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0.5em 0;
      width: 100%;
      color: white;
      background: #1F2421;
    }
    .menu > li:not(:last-child) {
      border-bottom: 1px solid #444;
    }
  }

  .nav__btn-get-in-touch-1{
    display: none;
  }
  .nav__btn-mp-1{
    display: none;
  }

  .nav__burger-menu{
    display: none;
  }
  
  .nav__btn-mp-1{
    display: none;
  }
  
  .nav__btn-get-in-touch-1{
    display: none;
  }
  
  
  @include lg{
    .nav__links{
      display: none;
    }
    .nav__btn-get-in-touch-1{
      display: block;
    }
    .nav__btn-mp-1{
      display: block;
    }
    .nav__btn-get-in-touch{
      display: none;
    }
    .nav__btn-mp{
      display: none;
    }
    .nav__burger-menu{
      display: block;
      width: 40px;
      height: 40px;
    }
}
