
$screen-s-max: 483px;

// Small tablets and large smartphones (landscape view)
$screen-sm-max: 576px;

// Small tablets (portrait view)
$screen-md-max: 768px;

// Tablets and small desktops
$screen-lg-max: 992px;

// Large tablets and desktops
$screen-xl-max: 1200px;

// Primary color
$primary-color: #ffffff;

//Secondary color
$secondary-color: #61B3BA;

//Third color
$third-color: #91D5DB;

// Fourth color
$fourth-color: #10152E;

// xl size
$xl-size: 40px;

// lg size
$lg-size: 28px;

// md size
$md-size: 24px;

// sm size
$sm-size: 20px;

$bright-grey: #EAEAEA;

$yellow-orange-gradient: linear-gradient(284deg, #FFA939 -36.16%, #FDDD33 97.4%);

$Dark-grey: #474747;

$Yellow: #FDDD33;

$Green-yellow-gradient: linear-gradient(91deg, #B8FD33 0%, #FDDD33 105.62%);