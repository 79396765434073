.container{
    background: #1F2421;
    width: 100%;
}



.wrapper{
    font-family: 'Dela Gothic One', sans-serif;
    font-family: 'Sen', sans-serif;
    background: #1F2421;
    max-width: 1440px;
    margin: 0 auto;
}

html,body{
    height: 100%;
    width: 100%;
}


.wrapper__terms{
    background: #c3c9af;
    padding: 35px 20px;
    &-btn{
        font-size: 20px;
        margin-bottom: 20px;
    }
    &-title{
        font-size: 28px;
        font-weight: 700;
        padding-bottom: 20px;
    }
    &-text{
        padding-top: 10px;
        &-title{
            font-size: 18px;
            font-weight: 600;
        }
    }
}