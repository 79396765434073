.about{
    display: inline-flex;
    padding: 0px 100px 100px 100px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    @include xl{
        padding: 0 40px 40px 40px;
        flex-direction: column;
    }
    @include sm{
        padding:  0 20px 20px 20px;
    }
    &__title{
        @include h2;
        @include h2_color;
        @include lg{
            @include h2-lg;
        }
        @include xl{
            padding-bottom: 30px;
        }
        @include sm{
            padding: 30px 0 20px 0;
        }
        @include s{
            padding: 20px 0 10px 0; 
            font-size: 28px;
        }
    }
    &__container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        gap: 94px;
        @include xl{
            gap: 34px;
        }
        @include article;
        flex-wrap: wrap;
        @include s{
            font-size: 12px;
            line-height: 20px; /* 166.667% */
        }
        &-left{
            display: flex;
            width: 320px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 48px;
            flex-shrink: 0;
            @include xl{
                width: 100%;
                gap: 24px;
            }
            @include s{
                gap: 10px;
            }    
        }
        &-center{
            background-image: url(/assets/about/payment.png);
            background-repeat: no-repeat;
            width: 360px;
            height: 340px;
            background-size: 100%;
            @include xl{
                margin: 0 auto;
            }
            @include s{
                width: 144px;
                height: 136px;
                background-size: contain;
            }
        }
        &-right{
            display: flex;
            width: 320px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 48px;
            flex-shrink: 0;
            @include xl{
                width: 100%;
                gap: 24px;
            }
            @include s{
                gap: 20px;
            }    
        }
    }
}

.elipse1{
    width: 572px;
    height: 590px;
    flex-shrink: 0;
    border-radius: 750px;
    border: 16px solid #B8FD33;
    position: absolute;
    right: 0;
    transform: translate(80%,65%);
}