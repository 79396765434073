.advantages{
    display: flex;
    padding: 0px 100px 100px 100px;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px; 
    @include xl{
        padding: 0 40px 40px 40px;
        gap: 30px;
    }
    @include sm{
        gap: 10px;
        padding:  0 20px 20px 20px;

    }
    &__title{
        @include h2;
        @include h2_color;
        @include xl{
            padding-bottom: 30px;
        }
        @include lg{
            @include h2-lg;
        }
        @include sm{
            padding: 30px 0 20px 0;
        }
        @include s{
            @include h2-s;
        }
    }
    &__wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        align-self: stretch;
        @include sm{
            gap: 10px;
        }
        &-container{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;
            flex-wrap: wrap;
            &-info{
                display: flex;
                align-items: flex-start;
                gap: 48px;
                @include sm{
                    gap: 16px;
                }
            }
            &-img-1{
                width: 161.778px;
                height: 200px;
                flex-shrink: 0;
                background-image: url(/assets/advantages/featches.png);
                background-repeat: no-repeat;
                @include xl{
                    margin: auto;
                }
                @include s{
                    width: 164px;
                    height: 154px;
                    background-size: contain;
                }
            }
            &-img-2{
                width: 200px;
                height: 200px;
                flex-shrink: 0;
                background-image: url(/assets/advantages/featches2.png);
                background-repeat: no-repeat;
                @include xl{
                    margin: auto;
                }
                @include s{
                    width: 193px;
                    height: 140px;
                    background-size: contain
                }
            }
            &-img-3{
                width: 219px;
                height: 219px;
                flex-shrink: 0;
                background-image: url(/assets/advantages/featches3.png);
                background-repeat: no-repeat;
                @include xl{
                    margin: auto;
                }
                @include s{
                    width: 193px;
                    height: 140px;
                    background-size: contain
                }
            }
            &-button{
                @include button;
                @include s{
                    @include button-s;
                }
            }
        }
    }
    &__container{
        &-number{
            display: flex;
            width: 120px;
            height: 132px;
            padding: 0px 21px 40px 21px;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-family: Dela Gothic One;
            font-size: 132px;
            font-style: normal;
            font-weight: 400;
            line-height: 92px; /* 69.697% */
            background: linear-gradient(284deg, #FFA939 -36.16%, #FDDD33 97.4%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @include xl{
                height: 106px;
            }
            @include sm{
                font-size: 64px;
                padding: 0px 9px 4px 9px;
                width: auto;
            }
            @include s{
                font-size: 36px;
                line-height: 36px;
                height: 40px;
            }
        }
        &-article{
            width: 300px;
            color: $bright-grey;
            text-align: justify;
            font-family: Sen;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;

            @include sm{
                width: 100%;
            }
            @include s{
                font-size: 12px;
            }
        }
    }
}

