@mixin s {
    @media (max-width: #{$screen-s-max}) {
        @content;
    }
 }

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
 }
 
 // Medium devices
 @mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
 }
 
 // Large devices
 @mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
 }
 
 // Extra large devices
 @mixin xl {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
 }

 // Flexible
 @mixin flexible ($justify-content) {
     display: flex;
     align-items: center;
     justify-content: $justify-content;
 }


 @mixin h1 () {
    font-family: Sen;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
 }

 @mixin h2-lg () {
    font-size: 40px;
    padding-bottom: 20px;
 }


@mixin h2 () {
    font-family: Sen;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 60px;
}

@mixin h2-s () {
    padding: 20px 0 10px 0; 
    font-size: 28px;
}

@mixin article-s () {
    font-size: 12px;
    line-height: 20px; /* 166.667% */
}


@mixin h2-md () {
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.15em;
    margin-bottom: 25px;
}


@mixin h2-sm () {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.14em;
}


@mixin h3 () {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $primary-color;
    }


@mixin h4 (){
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #91D5DB;
}


 @mixin article () {
    /* Body txt (reg) 18 */
    font-family: Sen;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    color: $bright-grey;
 }


@mixin subtitle (){
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    color: $primary-color;
}


@mixin subtitle-md (){
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
}


@mixin subtitle-sm (){
    font-size: 12px;
    line-height: 20px;
}

@mixin card-subtitle (){
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: $primary-color;
}


@mixin card () {
    background: rgba(0, 8, 43, 0.3);
    border: 3px solid rgba(145, 213, 219, 0.2);
    border-radius: 20px;
    padding: 35px;
}

@mixin card-lg () {
    background: rgba(0, 8, 43, 0.3);
    border: 3px solid rgba(145, 213, 219, 0.2);
    border-radius: 20px;
    padding: 20px;
}



@mixin footer-icon (){
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: $primary-color;
}

@mixin h5 (){
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: $primary-color;
}

@mixin footer-link (){
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: $primary-color;
    opacity: 0.8;
}

// modal heading
@mixin modal-h1 (){
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $third-color;
}

// modal text
@mixin modal-text (){
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: $primary-color;
}

@mixin button(){
    display: flex;
    padding: 12px 32px;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    border: 2px solid #FFA939;
    background: $yellow-orange-gradient;
    color: $Dark-grey;
    text-align: center;
    font-family: Sen;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
}

@mixin button-s(){
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
    font-family: Sen;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
    border-radius: 17px;
    border: 2px solid #FFA939;
    }

@mixin h2_color(){
    background: linear-gradient(91deg, #B8FD33 0%, #FDDD33 105.62%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}