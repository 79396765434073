.contacts{
    display: flex;
    padding: 0px 100px 100px 100px;
    flex-direction: column;
    align-items: center;
    @include xl{
        padding: 0 40px 40px 40px;
    }
    @include s{
        padding: 0 20px 20px 20px;
    }
    &__title{
        @include h2;
        @include h2-color;
        @include lg{
            @include h2-lg;
        }
        @include s{
            @include h2-s;
            
        }
    }
    &__container{
        display: flex;
        padding-bottom: 0px;
        gap: 100px;
        justify-content: space-between;
        align-items: flex-start;
        @include xl{
            gap: 50px;
        }
        @include lg{
            flex-direction: column;
            gap: 0;
        }
        &-left{
            width: 522px;
            @include lg{
                width: 100%;
            }
            &-box{
                display: flex;
                align-items: flex-start;
                gap: 56px;
                @include s{
                    gap: 10px;
                }
                &-title{
                    color: $bright-grey;
                    text-align: center;
                    font-family: Sen;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    transform: rotate(180deg);
                    writing-mode: vertical-rl;
                    align-items: center;
                    @include s{
                        font-size: 20px;
                    }
                    &-yellow{
                        color: #FDDD33;
                        font-family: Dela Gothic One;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        @include s{
                            font-size: 20px;
                        }
                    }    
                }
                &-bullet{
                    @include article;
                    gap: 24px;
                    @include s{
                        font-size: 12px;
                        gap: 10px;
                    }
                    &-aim{
                        display: flex;
                        padding-bottom: 16px;
                        align-items: center;
                        @include s{
                            padding-bottom: 8px;
                        }
                        &1{
                            margin-top: 24px;
                        } 
                    }
                    &-aim::before{
                        content: url("/assets/services/mark.png");
                        width: 32.684px;
                        height: 24px;
                        flex-shrink: 0;
                        margin-right: 16px;
                        @include s{
                            @include s{
                                content: url("/assets/services/mark-s.png");
                                width: 17px;
                                height: 12.483px;
                                margin-right: 8px;
                              }  
                        }
                    }     
                }
            }
            &-img{
                width: 330.732px;
                height: 320px;
                flex-shrink: 0;
                background-image: url(/assets/contacts/metalic.png);
                background-repeat: no-repeat;
                margin: 211px 0 0 66px; 
                @include lg{
                    background-size: contain;
                    width: 155px;
                    height: 155px;
                    margin: 0 auto;
                } 
            }
        }
        &-right{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 32px;
            @include lg{
                width: 100%;
                padding-bottom: 30px;
            }
        }
    }
}