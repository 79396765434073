.modal{
    display: none;
    width: 80vw;
    max-height: 80vh;
    background: #FFFADF;
    overflow: auto;
    margin: 0 auto;
    border-radius: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    &-content{
        padding: 60px;
    }
    &__containers{
        color: $primary-color;
        opacity: 1;
        transform: none;
    }
    &__container{
        display: flex;
        &-bottom{
            position: relative;
        }
        &-img{
            padding: 0 80px 50px 20px;
        }
        &-article{
            &-title{
                @include modal-h1;
                padding-bottom: 25px;
            }
            &-text{
                @include modal-text;
            }
        }
    }
}

.modal__form{
    display: none;
    border: 4px solid #FDDD33;
    color: grey;
    font-size: 24px;
    text-align: center;
    padding: 20px;
    width: 40vw;
    background: #FFFADF;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 20px;
    position: fixed;    
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    letter-spacing: 0.04em;
    &-close{
        color: #4F5369;
        float: right;
        font-size: 24px;
        position: relative;
        bottom: 18px;
        left: 10px;
        cursor: pointer;
        &:hover{
            color: $third-color;
        }
    }
    &-fill{
        display: none;
    }
    &-error{
        display: none;
    }
}

.modal__form::after{
    opacity: 1;
	visibility: visible;
}

.close {
    color: #4F5369;
    float: right;
    font-size: 28px;
    position: relative;
    bottom: 40px;
    left: 20px;
    cursor: pointer;
    &:hover{
        color: $third-color;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 5;
    display: none;
}

.overlay.is-show{
    display: block;
}

@include xl{
    .modal{
        &-content{
            padding: 30px;
        }

        &__container{
            &-img{
                padding: 0 80px 50px 20px;
            }
            &-article{
                &-title{
                    font-size: 18px;
                    padding-bottom: 15px;
                }
                &-text{
                    font-size: 12px;
                    margin-bottom: 20px;
                }
                &-logo{
                    display: none;
                }
            }
        }
    }
    .close {
        color: #4F5369;
        float: right;
        font-size: 28px;
        bottom: 20px;
        left: 10px;
      }
}

@include lg{
    .modal{
        overflow-y: auto;
        &-content{
            padding: 20px;
        }
        &__container{
            align-items: center;
            &-img{
                width: 250px;
                height: 300px;
                padding: 0 20px 30px 20px;
            }
            &-article{
                &-title{
                    font-size: 18px;
                    padding-bottom: 15px;
                }
                &-text{
                    font-size: 12px;
                }
            }
        }
    }
}

@include md{
    .modal{
        &-content{
            padding: 10px;
        }
        &__container{
            flex-direction: column;
            align-items: center;
            &-img{
                width: 180px;
                height: 180px;
            }
            &-article{
                &-title{
                    font-size: 18px;
                    padding-bottom: 15px;
                    text-align: center;
                }
                &-text{
                    text-align: center;
                    font-size: 12px;
                }
            }
        }
    }
}

@include sm{
    .modal{
        &__container{
            flex-direction: column;
            align-items: center;
            &-img{
                width: 180px;
                height: 180px;
            }
            &-article{
                &-title{
                    @include h2-sm;
                    padding-bottom: 15px;
                    text-align: center;
                }
                &-text{
                    text-align: center;
                    @include subtitle-sm;
                }
            }
        }
    }
    .modal__form{
        min-width: 200px;
        padding: 10px;
        &-close{
            bottom: 10px;
            left: 0;
        }
    }
}

