.form {
	margin: 0px auto;
	color: grey;
    border-radius: 20px;
    &__body{
		display: flex;
		flex-direction: column;
        position: relative;
    }
    &__title{
        @include h2;
        margin: 0px 0px 30px 0px;
		@include s{
            @include h2-s;
        }
    }
    &__subtitle{
        @include subtitle;
        margin-bottom: 70px;
    }
    &__second-title{
        color: $bright-grey;
		/* Heading (bold) 22 */
		font-family: Sen;
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 160%; /* 35.2px */
        margin: 30px 0 20px 0;
		display: inline-block;
		@include s{
			margin: 10px 0 0 0;
		}
    }
    &__top-container{
        display: flex;
		flex-direction: column;
	}
}

.test{
    display: flex;
    align-items: center;
}

.form * {
	outline: none;
}


.form__body::after {
	content: "";
	position: absolute;
	top: -100px;
	left: -100px;
	width: 121%;
	height: 114%;
	background: rgba(26, 26, 26, 0.9) url("/assets/form/loading.gif") center / 50px no-repeat;
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s ease 0s;
}
.form__body._sending::after {
	opacity: 1;
	visibility: visible;
}

.form__item {
	margin: 0px 0px 20px 0px;
	@include s{
		margin:  0 0 10px 0;
	}
}
.form__label {
	font-size: 18px;
	display: block;
	margin: 0px 0px 10px 0px;
}
.form__input {
	display: flex;
	width: 600px;
	height: 76px;
	padding: 20px 248px 20px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	transition: all 0.5s ease 0s;
    border-radius: 20px;
	border: 3px solid #FFFADF;
	background: #FFFADF;
	text-indent: 24px;
	@include xl{
		width: 100%;
		padding: 0;
	}
	@include s{
		height: 40px;
		border-radius: 12px;
	}
}

.form__input:focus {
	border-radius: 20px;
	border: 4px solid #FDDD33;
	background: #FFFADF;
}

.form__item:hoover{
	background: red;
}

.form__input._error {
	border: 3px solid #FD3333;
}

textarea.form__input {
	resize: vertical;
}

.options {
	padding: 10px 0px 0px 0px;
}
.options__item {
	margin: 0px 0px 10px 0px;
}
.options__input {
	display: none;
}

.options__input:checked + .options__label::after {
	transform: scale(1);
}

.options__label {
	display: inline-flex;
	font-size: 16px;
	line-height: 140%;
	align-items: center;
	position: relative;
	cursor: pointer;
}
.options__label::before {
	content: "";
	align-self: flex-start;
	flex: 0 0 24px;
	height: 24px;
	background-color: #fff;
	border-radius: 50%;
	margin: 0px 10px 0px 0px;
}
.options__label::after {
	transition: transform 0.5s ease 0s;
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	width: 16px;
	height: 16px;
	background-color: #7a956b;
	border-radius: 50%;
	transform: scale(0);
}

.checkbox._error .checkbox__label::before {
	box-shadow: 0 0 15px red;
}
.checkbox__input {
	display: none;
}
.checkbox__input:checked + .checkbox__label::after {
	transform: scale(1);
}

.checkbox__label {
	font-size: 16px;
	width: 100%;
	line-height: 140%;
	display: inline-flex;
	align-items: center;
	position: relative;
	cursor: pointer;
}

.checkbox__label::before {
	content: "";
	align-self: flex-start;
	flex: 0 0 24px;
	height: 24px;
	width: 24px;
	border-radius: 15px;
	border: 2px solid #FFA939;
	margin: 0px 10px 0px 0px;
}
.checkbox__label::after {
	transition: transform 0.5s ease 0s;
	content: "";
	width: 24px;
	height: 24px;
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 15px;
	border: 2px solid #FFA939;
	background: linear-gradient(284deg, #FFA939 -36.16%, #FDDD33 97.4%);
	border-radius: 100%;
	transform: scale(0);
}

.checkbox__label a {
	color: yellow;
}

.form__button {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.5s ease 0s;
    margin: 0 auto;
	@include button;
	@include sm{
		padding: 4px 24px;
		border: 1px solid #FFA939;
		font-size: 20px;
	}
}

.form__button:active {
	top: 3px;
}

.form__label-help{
	display: flex;
	width: 600px;
	height: 76px;
	padding: 20px 248px 20px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
    writing-mode: horizontal-tb !important;
    font-family: 'Montserrat';
    font-style: normal;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-rtl-ordering: logical;
    resize: auto;
    cursor: text;
    overflow-wrap: break-word;
    column-count: initial !important;
	text-indent: 24px;
	@include xl{
		width: 100%;
		padding: 26px 0;
	}
}

.rules-confirm{
    display: flex;
    margin-right: 10px;
    margin-bottom: 10px;
	@include xl{
		margin-right: 0px;
		display: block;
	}
}

.rules-link{
    margin-left: 10px;
	color: yellow;
}
